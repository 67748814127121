import React from 'react';
import './App.scss';
import HomePage from './pages/home/home.page';

export default function App() {

    return (
        <HomePage></HomePage>
    )

}