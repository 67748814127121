import React, { ReactNode } from "react"
import { Letreiro } from "../../../../../../components/html/letreiro/letreiro"
import './screen-metodo.scss'
import ScrollMagic from 'scrollmagic';
import { ScreenMetodoIntroComponent } from "./screen-metodo-intro";
//import imgStudio from './../../../../../../assets/studio.png'
import imgStudio from './../../../../../../assets/studio-mao-2.jpg'
import { ScreenMetodoComoTrabalhamosComponent } from "./screen-metodo-como-trabalhamos";

export class ScreenMetodoComponent extends React.Component{

    protected controller!:ScrollMagic.Controller
    protected ref_page:React.RefObject<HTMLDivElement>
    protected ref_letreiro:React.RefObject<Letreiro>

    constructor(props:any){
        super(props)
        this.controller = new ScrollMagic.Controller();
        this.ref_page = React.createRef()
        this.ref_letreiro = React.createRef()
    }

    componentDidMount(){
        this.montaScrollbarScene()
    }

    montaScrollbarScene(){

        new ScrollMagic.Scene({
                'triggerElement':this.ref_page.current, 
                'duration':this.ref_page.current!.clientHeight
            })
            .on("enter leave", (e:ScrollMagic.Event<ScrollMagic.EventType>) => {

                if(e.type === 'enter'){
                    this.ref_letreiro.current?.animar()
                }
                
            })
            //.addIndicators!() // add indicators (requires plugin)
            .addTo(this.controller);

    }

    render(): ReactNode {
        return (
            <>
                <div ref={this.ref_page} className="metodo page-wrapper" id="metodo-trigger">

                    <div className="wrapper-text">
                        <h6 className="subtitle">O que fazemos</h6>
                        <h1 className="title" data-aos="fade-right" data-aos-delay="100" data-aos-once="true" style={{'marginBottom':0}}>Nós projetamos e desenvolvemos</h1>
                        <h1 className="supertitle title-marginbottom" data-aos="fade-up" data-aos-delay="200" style={{'marginTop':'-10px', 'marginBottom':'32px'}}>
                            <Letreiro ref={this.ref_letreiro}>
                                <div>soluções</div>
                                <div>aplicativos</div>
                                <div>plataformas</div>
                                <div>experiências</div>
                                <div>conhecimento</div>
                            </Letreiro>
                        </h1>
                    </div>

                    <div className="wrapper">
                        <ScreenMetodoIntroComponent></ScreenMetodoIntroComponent>
                    </div>

                    <div style={{display:'flex', justifyContent:'flex-end', marginTop:'62px'}}>
                        <img className="img" src={imgStudio} alt={'Desenvolvimento de soluções'} style={{height:'35vh'}}></img>
                    </div>

                    <div className="wrapper-text" style={{marginTop:'128px'}}>
                        <h6 className="subtitle">Como trabalhamos ?</h6>
                        <h1 className="title title-marginbottom" data-aos="fade-right" data-aos-delay="100" data-aos-once="true" style={{}}>Design, design, design...</h1>
                    </div>

                    <div className="wrapper">
                        <ScreenMetodoComoTrabalhamosComponent></ScreenMetodoComoTrabalhamosComponent>
                    </div>

                </div>

            </>
        )
    }

}