import React from "react"
import './mottive-logo.scss'
import anime from 'animejs';

export class MottiveLogo extends React.Component<any, {name:string, width:number|string, glow:boolean, opacity:number}>{

    protected ref_svg:React.RefObject<SVGSVGElement>
    protected elements_bars?:NodeListOf<HTMLElement>
    protected elements_letters?:NodeListOf<HTMLElement>

    constructor(public props:{name:string, width:number, glow?:boolean, opacity?:number}) {
        super(props)
        this.ref_svg = React.createRef()
        this.state = {'name':props.name, 'width':props.width, 'glow':props?.glow ?? false, 'opacity':props.opacity ?? 1}
    }

    protected style(){

        return {
            'width': this.state.width,
            'height': 'auto',
            'opacity': this.state.opacity
        }

    }

    animate(){
        this.animateLogo()
    }

    animateLogo(){
        
        anime
            .timeline({loop:false})
            .add({
                targets: this.elements_bars,
                translateX: [-10,0],
                translateZ: 0,
                opacity: [0,1],
                easing: "easeOutExpo",
                duration: 1200,
                delay: (el, i) => 500 + 100 * i
            })
            .add({
                targets: this.elements_letters,
                translateX: [40,0],
                translateZ: 0,
                opacity: [0,1],
                easing: "easeOutExpo",
                duration: 1200,
                delay: (el, i) => 500 + 60 * i,
                complete:() => {
                    this.glow()
                }
            }, 500)

    }

    glow(){
        this.ref_svg.current?.classList.add('glow')
    }

    unglow(){
        this.ref_svg.current?.classList.remove('glow')
    }

    animateTexto(){

    }

    componentDidMount(){

        this.elements_bars = this.ref_svg.current?.querySelectorAll("g[name='group-logo'] > *[name='bar']")
        this.elements_letters = this.ref_svg.current?.querySelectorAll("g[name='group-texto'] > *[name='letter']")
        console.log(this.elements_letters, this.elements_bars)
        this.animate()
    }
    
    render(){

        return (
            <div>

                <svg ref={this.ref_svg} version="1.1" id={this.props.name} className={'mottive-logo'} style={this.style()} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 211 41" xmlSpace="preserve">
                    
                    <g name='group-logo' id="logo" fill="#2DB192">
                        <path name="bar" bar-position="1" id="logo-bar-1" fill="#2DB192" d="m2.8 41-.4-.1C.5 40.2-.5 38.1.2 36.1l7.2-19.9c.7-1.9 2.8-2.9 4.7-2.2l.4.1c1.9.7 2.9 2.8 2.2 4.8L7.6 38.8c-.7 1.9-2.8 2.9-4.8 2.2z"/>
                        <path name="bar" bar-position="2" id="logo-bar-2" fill="#2DB192" d="m13 41-.4-.1c-1.9-.7-2.9-2.8-2.2-4.8l7.2-19.9c.7-1.9 2.8-2.9 4.7-2.2l.4.1c1.9.7 2.9 2.8 2.2 4.8l-7.2 19.9c-.6 1.9-2.8 2.9-4.7 2.2z"/>
                        <path name="bar" bar-position="3" id="logo-bar-3" fill="#2DB192" d="M35.3 17.7c0-1.5-.9-3-2.4-3.5l-.4-.2c-1.9-.7-4 .3-4.7 2.2l-7.2 19.9c-.7 1.9.3 4.1 2.2 4.8l.4.1c.4.2.9.2 1.3.2h6.9c2.2 0 3.9-1.8 3.9-3.9V17.7z"/>
                    </g>

                    <g name='group-texto' id="texto" fill="#2DB192">
                        <path name="letter" letter-description="m" id="m" d="M77 25c.1.1.1.2.1.3v13.4c0 1.5-1.3 2.7-2.7 2.7s-2.7-1.2-2.7-2.7v-14c0-3.1-2.5-5.6-5.6-5.6s-5.6 2.5-5.6 5.6v13.9c0 1.5-1.2 2.7-2.7 2.7s-2.7-1.2-2.7-2.7V24.7c0-3.1-2.5-5.6-5.6-5.6s-5.6 2.5-5.6 5.6v13.9c0 1.5-1.2 2.7-2.7 2.7-1.5 0-2.8-1.2-2.8-2.7V24.7c0-6 4.9-11 11.1-11 3.3 0 6.3 1.4 8.3 3.8 2-2.4 5-3.8 8.3-3.8 6.1 0 11.1 4.9 11.1 11-.2.2-.2.2-.2.3z"/>
                        <path name="letter" letter-description="o" id="o" d="M80.1 27.4c0-7.6 6.2-13.8 13.8-13.8 7.6 0 13.8 6.2 13.8 13.8 0 7.6-6.2 13.8-13.8 13.8-7.6 0-13.8-6.2-13.8-13.8zm5.5 0c0 4.6 3.7 8.4 8.4 8.4 4.6 0 8.4-3.8 8.4-8.4 0-4.6-3.7-8.4-8.4-8.4-4.7.1-8.4 3.8-8.4 8.4z"/>
                        <path name="letter" letter-description="t1" id="t1" d="M126.4 16.4c0 1.5-1.2 2.7-2.7 2.7h-3.9v19.4c0 1.5-1.2 2.8-2.8 2.8-1.5 0-2.7-1.3-2.7-2.8V19.1h-4c-1.5 0-2.7-1.3-2.7-2.7 0-1.5 1.2-2.7 2.7-2.7h4v-11c0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.8 1.2 2.8 2.7v11h3.9c1.5 0 2.7 1.2 2.7 2.7z"/>
                        <path name="letter" letter-description="t2" id="t2" d="M148.5 16.4c0 1.5-1.2 2.7-2.8 2.7h-3.9v19.4c0 1.5-1.2 2.8-2.8 2.8-1.5 0-2.7-1.3-2.7-2.8V19.1h-4c-1.5 0-2.7-1.3-2.7-2.7 0-1.5 1.2-2.7 2.7-2.7h4v-11c0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.8 1.2 2.8 2.7v11h3.9c1.6 0 2.8 1.2 2.8 2.7z"/>
                        <path name="letter" letter-description="i" id="i" d="M152.7 7.4c-.5-.5-.8-1.2-.8-1.9a2.732 2.732 0 0 1 2.7-2.7c.7 0 1.4.3 1.9.8s.8 1.2.8 1.9a2.732 2.732 0 0 1-2.7 2.7c-.7 0-1.5-.3-1.9-.8zm-.9 31.1V16.4c0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7v22.2c0 1.5-1.2 2.8-2.7 2.8-1.5-.1-2.7-1.3-2.7-2.9z"/>
                        <path name="letter" letter-description="v" id="v" d="m182.6 17.4-8.2 22c-.4 1.1-1.5 1.8-2.6 1.8h-.1c-1.2 0-2.2-.8-2.6-1.8l-8.2-22c-.6-1.4.2-3 1.6-3.5 1.4-.5 3 .2 3.5 1.6l5.8 15.3 5.7-15.3c.5-1.4 2.1-2.1 3.5-1.6s2.1 2.1 1.6 3.5z"/>
                        <path name="letter" letter-description="e" id="e" d="M211.3 27.4c0 1.5-1.2 2.7-2.8 2.7h-19c1.2 3.3 4.2 5.7 7.9 5.7 1.3 0 3.5-.1 6.2-1.8 1.3-.8 3-.1 3.6 1.3.7 1.4-.1 2.8-1.3 3.6-3.6 2.4-6.4 2.4-8.5 2.4-7.6 0-13.8-6.2-13.8-13.8 0-7.6 6.2-13.8 13.8-13.8 6.7-.1 13.9 4.8 13.9 13.7zm-21.7-2.7h15.9c-1.2-3.8-4.7-5.6-8-5.6-3.7 0-6.8 2.3-7.9 5.6z"/>
                    </g>

                </svg>

            </div>
        )

    }

}