import React from "react"

export class ScreenMetodoIntroComponent extends React.Component{

    render(): React.ReactNode {
        return <>
            <div className="metodo-intro-texto">
                <p>
                    Produtos de alta qualidade exigem mais do que apenas um design criativo e um código inteligente.
                    É fundamental que haja uma verdadeira colaboração entre os idealizadores e os desenvolvedores.
                </p>
                <p>     
                    Nossa parceria é baseada em transparência, comunicação eficiente e gerenciamento de projetos cuidadoso, 
                    prestando atenção a cada detalhe. Isso nos permite criar produtos excepcionais.                    
                </p>
            </div>
        </>
    }

}