import React from "react"
import './mottive-text.scss'
import anime from 'animejs';

export class MottiveText extends React.Component<any, {aparecerVerde:boolean}>{

    protected spanDefault = React.createRef<HTMLSpanElement>()
    protected spanNew = React.createRef<HTMLSpanElement>()

    constructor(public props:{children:string, text:string, onComplete?:() => void}) {
        super(props)
        this.state = {aparecerVerde:false}
    }

    animar(){

        anime
            .timeline({loop:false})
            .add({
                'targets':this.spanDefault.current,
                delay:1000,
                opacity:[
                    {value:0, duration:50},
                    {value:0.5, duration:200},
                    {value:1, duration:50, endDelay:800},
                    {value:0, duration:50},
                    {value:1, duration:50},
                    {value:0.5, duration:30},
                    {value:0.0, duration:30},
                    {value:0.9, duration:30},
                    {value:0.1, duration:30},
                    {value:0, duration:50, endDelay:100},
                ],
                complete:() => {
                    this.spanDefault.current!.textContent = this.props.text
                }
            })
            .add({
                'targets':this.spanDefault.current,
                opacity:[0.5,0],
                duration:50,
                endDelay:400,
                complete:() => {
                    this.aparecerVerde()
                }
            })

    }

    aparecerVerde(){

        this.setState({aparecerVerde:true})
        
        anime
            .timeline({loop:false})
            .add({
                delay:2000,
                targets:this.spanNew.current,
                opacity:[
                    {value:0, duration:50},
                    {value:1, duration:50},
                    {value:0, duration:50},
                    {value:1, duration:150},
                ],
                complete:() => {
                    this.aparecerBranco()
                }
            })
    }

    aparecerBranco(){

        this.setState({aparecerVerde:false})
        this.spanDefault.current!.textContent = this.props.children
        
        anime
            .timeline({loop:false})
            .add({
                'targets':this.spanDefault.current,
                opacity:[0,1],
                duration:60,
                endDelay:800,
                complete: () => {

                    if( this.props.onComplete ){
                        this.props.onComplete()
                    }

                }
            })
    }

    render(){
        return (
            <span className={"mottive-text " + ( this.state.aparecerVerde ? 'aparecer-verde' : '' )} aria-label={this.props.children} style={{display:'inline-block'}}>
                <span className='cover' ref={this.spanDefault}>{this.props.children}</span>
                <span className='default' ref={this.spanDefault}>{this.props.children}</span>
                <span className='new' ref={this.spanNew}>{this.props.text}</span>
            </span>
        )
    }

}