import React, { ReactNode } from "react"
import { MottiveLogoTexto } from "../../../../../../components/html/mottive-logo-texto/mottive-logo-texto"
import './screen-contact.scss'

export class ScreenContactComponent extends React.Component{

    componentDidMount(){

    }

    render(): ReactNode {
        
        return (
            <>
                <div className="contato-1">
                    <div className="texto"  data-aos="fade-up">
                        <div style={{'marginBottom':'14px'}}>Entre em contato e vamos desenvolver projetos grandiosos juntos.</div>
                        <div><a href='mail:contato@mottivetecnologia.com.br'>contato@mottivetecnologia.com.br</a></div>
                        <div><a href='tel:+19999421402'>(19) 99942-1402</a></div>
                    </div>
                </div>
                
                <div className="contato-2">
                    <MottiveLogoTexto name="logo-texto" width='300px' color="rgba(255,255,255,0.1)"></MottiveLogoTexto>
                </div>
            </>
        )
    }

}