import './screen-1.scss'
import anime from 'animejs';
import React from 'react';
import ScrollMagic from 'scrollmagic'
import { MottiveText } from '../../../../../../components/html/mottive-text/mottive-text';
import { MottiveLogo } from '../../../../../../components/html/mottive-logo/mottive-logo';
import { ScrollDownAnimation } from '../../../../../../components/html/scroll-down/scroll-down-animation';

export class ScreenOneComponent extends React.Component<any, any>{
//this.ref_svg.current?.classList.add('glow')
    protected ref_page:React.RefObject<HTMLDivElement>
    protected ref_logo_mottive:React.RefObject<MottiveLogo>
    protected textoMotivo:React.RefObject<MottiveText>
    protected textoMotivacao:React.RefObject<MottiveText>
    protected scrollController!:ScrollMagic.Controller

    constructor(public props:any){
        super(props)
        this.textoMotivo = React.createRef();
        this.textoMotivacao = React.createRef();
        this.state = {putLogo:false}
        this.scrollController = new ScrollMagic.Controller()
        this.ref_page = React.createRef()
        this.ref_logo_mottive = React.createRef()
    }

    montaScrollScene(){
        
        new ScrollMagic.Scene({
                'triggerElement':this.ref_page.current, 
                'duration':this.ref_page.current!.clientHeight
            })
            .on("enter leave", (e:ScrollMagic.SceneProgressEvent<ScrollMagic.EventType>) => {
                
                if( e.type === 'enter' && e.scrollDirection === 'REVERSE' ){
                    this.glow()
                }

                if( e.type === 'leave' ){
                    this.unglow()
                }

            })
            .addTo(this.scrollController);

    }

    componentDidMount(){
        this.animarApresentacao()
        this.montaScrollScene()
    }

    glow(){
        this.ref_logo_mottive.current?.glow()
    }
    
    unglow(){
        this.ref_logo_mottive.current?.unglow()
    }
    
    animarApresentacao(){
        
        anime
            .timeline({loop:false})
            /*.add({ //
                targets: '.frase-1 > span > span[data-letter]',
                opacity: [0,1],
                easing: "easeInOutQuad",
                delay: (el, i) => 500 + ( 35 * (i+1) )
            })
            .add({
                targets: '.frase-2 > span > span[data-letter]',
                opacity: [0,1],
                easing: "easeInOutQuad",
                duration: 1000,
                delay: (el, i) => 200 + ( 35 * (i+1) )
            })*/
            .add({ // some com a frase-1
                targets: '.mensagem-1',
                opacity: 0,
                easing: "easeInOutQuad",
                duration: 500,
                //delay: 1000
            })
            .add({ // inicia a frase 2
                targets: '.mensagem-2 .texto > div',
                opacity: [0,1],
                easing: "easeInOutQuad",
                //duration: 1000,
                delay: (el, i) => ( 800 * (i+1) ),
                complete: (anim) => {
                    this.textoMotivo.current?.animar()
                    this.textoMotivacao.current?.animar()
                }
            }, '-=500')

    }

    onMottiveTextComplete(){

        anime
            .timeline({loop:false})
            .add({
                targets:'.mensagem-2',
                opacity: [1,0],
                easing: "easeInOutQuad",
                duration: 500
            })
            .add({
                targets:'.mottive-logo',
                opacity: [0,1],
                easing: "easeInOutQuad",
                duration: 300,
                complete:() => {
                    this.setState({putLogo:true})
                }
            })
    }

    render(): React.ReactNode {
        
        return (
            <div className="page-one" ref={this.ref_page}>
                
                { this.state.putLogo ? <ScrollDownAnimation></ScrollDownAnimation> : null }

                <div className="mensagem mensagem-1">
                    <div className="texto">
                        {/*
                        <div className="frase-1">
                            <SplitText>
                                Nós desenvolvemos soluções digitais.
                            </SplitText>
                        </div>
                        
                        <div className="frase-1">
                            <SplitText>
                                Crescimento vem acompanhado de complexidade
                            </SplitText>
                        </div>
                        <div className="frase-2">
                            <SplitText>
                                E só inteligência pode trazer de volta a simplicidade
                            </SplitText>
                        </div>
                        */}
                    </div>
                </div>

                <div className="mensagem mensagem-2">
                    <div className="texto">
                        <div className="frase-2">
                            Desenvolver é o nosso <MottiveText ref={this.textoMotivo} text="mottivo">motivo</MottiveText>
                        </div>
                        <div className="frase-1">
                            Criar soluções é a nossa <MottiveText ref={this.textoMotivacao} text="mottivação" onComplete={() => this.onMottiveTextComplete()}>motivação</MottiveText>
                        </div>
                    </div>
                </div>
                
                <div className="mensagem mensagem-3">
                    { this.state.putLogo ? <MottiveLogo ref={this.ref_logo_mottive} name="logo-principal" width={200}></MottiveLogo> : '' }
                </div>

            </div>
        )
    }

}