import React from "react"
import './mottive-logo-texto.scss'

export class MottiveLogoTexto extends React.Component<any, {name:string, width:number|string, color?:string}>{

    constructor(public props:{name:string, width:number|string, color?:string}) {
        super(props)
        this.state = {
            'name':props.name,
            'width':props.width,
            'color':props.color ?? '#fff'
        }
    }

    protected style(){

        return {
            'width': this.state.width,
            'height': 'auto',
        }

    }

    render(){

        return (
            <div>

                <svg version="1.1" id={this.props.name} className={'mottive-logo-texto'} style={this.style()} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 173 41.4" xmlSpace="preserve">
                    <g id="mottive" fill={this.state.color}>
                        <path id="m" d="M38.7 25c.1.1.1.2.1.3v13.4c0 1.5-1.3 2.7-2.7 2.7s-2.7-1.2-2.7-2.7v-14c0-3.1-2.5-5.6-5.6-5.6s-5.6 2.5-5.6 5.6v13.9c0 1.5-1.2 2.7-2.7 2.7s-2.7-1.2-2.7-2.7V24.7c0-3.1-2.5-5.6-5.6-5.6s-5.6 2.5-5.6 5.6v13.9c0 1.5-1.2 2.7-2.7 2.7-1.5 0-2.8-1.2-2.8-2.7V24.7c0-6 4.9-11 11.1-11 3.3 0 6.3 1.4 8.3 3.8 2-2.4 5-3.8 8.3-3.8 6.1 0 11.1 4.9 11.1 11-.2.2-.2.2-.2.3z"/>
                        <path id="o" d="M41.8 27.4c0-7.6 6.2-13.8 13.8-13.8 7.6 0 13.8 6.2 13.8 13.8 0 7.6-6.2 13.8-13.8 13.8-7.6 0-13.8-6.2-13.8-13.8zm5.4 0c0 4.6 3.7 8.4 8.4 8.4 4.6 0 8.4-3.8 8.4-8.4 0-4.6-3.7-8.4-8.4-8.4-4.6.1-8.4 3.8-8.4 8.4z"/>
                        <path id="t1" d="M88.1 16.4c0 1.5-1.2 2.7-2.7 2.7h-3.9v19.4c0 1.5-1.2 2.8-2.8 2.8-1.5 0-2.7-1.3-2.7-2.8V19.1h-4c-1.5 0-2.7-1.3-2.7-2.7 0-1.5 1.2-2.7 2.7-2.7h4v-11C76 1.2 77.2 0 78.6 0c1.5 0 2.8 1.2 2.8 2.7v11h3.9c1.5 0 2.8 1.2 2.8 2.7z"/>
                        <path id="t2" d="M110.2 16.4c0 1.5-1.2 2.7-2.8 2.7h-3.9v19.4c0 1.5-1.2 2.8-2.8 2.8-1.5 0-2.7-1.3-2.7-2.8V19.1h-4c-1.5 0-2.7-1.3-2.7-2.7 0-1.5 1.2-2.7 2.7-2.7h4v-11c0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.8 1.2 2.8 2.7v11h3.9c1.6 0 2.8 1.2 2.8 2.7z"/>
                        <path id="i" d="M114.3 7.4c-.5-.5-.8-1.2-.8-1.9a2.732 2.732 0 0 1 2.7-2.7c.7 0 1.4.3 1.9.8s.8 1.2.8 1.9a2.732 2.732 0 0 1-2.7 2.7c-.7 0-1.4-.3-1.9-.8zm-.8 31.1V16.4c0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7v22.2c0 1.5-1.2 2.8-2.7 2.8-1.5-.1-2.7-1.3-2.7-2.9z"/>
                        <path id="v" d="m144.3 17.4-8.2 22c-.4 1.1-1.5 1.8-2.6 1.8h-.1c-1.2 0-2.2-.8-2.6-1.8l-8.2-22c-.6-1.4.2-3 1.6-3.5 1.4-.5 3 .2 3.5 1.6l5.8 15.3 5.7-15.3c.5-1.4 2.1-2.1 3.5-1.6s2.1 2.1 1.6 3.5z"/>
                        <path id="e" d="M173 27.4c0 1.5-1.2 2.7-2.8 2.7h-19c1.2 3.3 4.2 5.7 7.9 5.7 1.3 0 3.5-.1 6.2-1.8 1.3-.8 3-.1 3.6 1.3.7 1.4-.1 2.8-1.3 3.6-3.6 2.4-6.4 2.4-8.5 2.4-7.6 0-13.8-6.2-13.8-13.8 0-7.6 6.2-13.8 13.8-13.8 6.7-.1 13.9 4.8 13.9 13.7zm-21.8-2.7h15.9c-1.2-3.8-4.7-5.6-8-5.6-3.6 0-6.7 2.3-7.9 5.6z"/>
                    </g>
                </svg>

            </div>
        )

    }

}