import React from "react";
import './scroll-down-animation.scss'
import anime from 'animejs'

export class ScrollDownAnimation extends React.Component{

    componentDidMount(){
        anime
            .timeline({loop:false})
            .add({
                targets:'.scroll-animation-icon',
                opacity: [0,0.3],
                easing: "easeInOutQuad",
                duration: 500,
                delay:4000
            })
    }

    render(): React.ReactNode {
        return (
            <div className={'scroll-animation-icon'} style={{'width':'40px', height:'40px'}}>
                <svg height="100%" viewBox="0 0 247 390" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{'fillRule':'evenodd','clipRule':'evenodd','strokeLinecap':'round','strokeLinejoin':'round'}}>
                    <path id="wheel" d="M123.359,79.775l0,72.843" style={{'fill':'none','stroke':'#fff','strokeWidth':'20px'}}/>
                    <path id="mouse" d="M236.717,123.359c0,-62.565 -50.794,-113.359 -113.358,-113.359c-62.565,0 -113.359,50.794 -113.359,113.359l0,143.237c0,62.565 50.794,113.359 113.359,113.359c62.564,0 113.358,-50.794 113.358,-113.359l0,-143.237Z" style={{'fill':'none','stroke':'#fff','strokeWidth':'20px'}}/>
                </svg>
            </div>
        )
    }

}