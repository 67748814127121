import React from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import StoryTellerComponent from './components/story-teller/story-teller.component'

export default function HomePage(){

    React.useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, [])

    return (
        <>
            <StoryTellerComponent></StoryTellerComponent>
        </>
    )

}