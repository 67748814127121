import './story-teller.scss';
import { ScreenOneComponent } from './screens/screen-1/screen-1';
import { ScreenContactComponent } from './screens/screen-contact/screen-contact';
import { ScreenDesenvolvimentoComponent } from './screens/screen-desenvolvimento/screen-desenvolvimento';
import { ScreenMetodoComponent } from './screens/screen-metodo/screen-metodo';

export default function StoryTellerComponent(props:any){

    return (
        <div className="stage">

            <section className="page">
                <ScreenOneComponent></ScreenOneComponent>
            </section>
            
            <section className="page">
                <ScreenMetodoComponent></ScreenMetodoComponent>
            </section>

            {/*
            <section className="page">
                <ScreenDesenvolvimentoComponent></ScreenDesenvolvimentoComponent>
            </section>
            */}

            <section className="page cinza">
                <ScreenContactComponent></ScreenContactComponent>
            </section>

        </div>
    )

}